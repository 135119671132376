#slider_wrapper{
  .frame {
    border: 10px solid #FF8E00;
    width: 100%;
    overflow: hidden; // to hide the other boxs
  }
  .about_detail_list{
    position: relative;
  }
  .item{
    border-radius: 10px;
    .carousel__inner-slide{
      width: 100%;
      height: auto;
      min-height: 500px;
      position: relative;
      border-radius: 10px;
      padding-top: 50%;
      
      div{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
      }
    }
  }
  
  .box-container{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  
  .box {
    box-sizing: border-box;
    background: #00E7FF;
    width: 80%;
    min-width: 500px;
    height: 600px;
    margin-right: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .button-container {
    display: flex;
  }
  
  .button {
    background: #666;
    color: #fff;
    width: 50px;
    text-align: center;
    padding: 10px 20px;
    margin: 10px;
    user-select: none;
    cursor: pointer;
  }
  
  .button-2-container {
    display: flex;
  }
  
  .button-2 {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: #E0E0E0;
    margin: 6px;
    cursor: pointer;
  }
  
  .active {
    background: #999;
  }
}
.slider_wrapper {

}

