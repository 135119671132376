
#Pages_about{
  margin-bottom: 200px;
}

#About_staff{
  position: relative;
  margin-top: -250px;
  z-index: 1;
  .about_department_list{
    margin-bottom: 35px;
    ul{
      display: flex;
      justify-content: space-evenly;
      li{
        
        &.active{
          color:white;
          border: 1px solid #fff;
          border-radius: 50px;
         
        }
        &:hover{
          color:white;
        }
      }
    }
  }
  .aboutstaff_list{
    display: flex;
    flex-wrap: wrap;
    .aboutstaff_item{
      flex: 0 0 23%;
      padding-top: 30%;
      margin-right:   1.5%;
      margin-bottom:   1.5%;
      border-radius: 6px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: grayscale(50%);
      transition: 0.3s;
      &:hover{
        transform: translateY(-5px);
      }
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0.3) 40%, rgb(26, 26, 26) 90%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-blend-mode: multiply;
      }
      &:nth-child(4n+0){
        margin-right: 0;
      }
      &:last-child{
        
      }
      .person_info{
        position: absolute;
        width: 100%;
        bottom: 10%;
        left:0;
        z-index: 1;
        .name{
          font-size: 35px;
          font-weight: bold;
          text-align: center;
          color: #f3f3f3;
        }
        .bar{
          width: 10px;
          height: 3px;
          background: #fff;
          margin: 18px auto;
       
        }
        .name_subtext{
          text-align: center;
          font-weight: 300;
          font-size: 12px;
        }
      }


    }
  }
}