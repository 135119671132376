

#Pages_education{
  margin-bottom: 200px;
}
.chartlist{
  margin-top: -12%;
  margin-bottom: 100px;
  ul{

    li{

      .chartlist_item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .imgicon{
          margin-bottom: 20px;
        }
        .title{
          font-size: 25px;
          font-weight: 800;
          letter-spacing: 2px;
        }
        .desc{
          text-align: center;
          font-size: 14px;
          width: 70%;
          margin-top: 15px;

        }
        .number{
          font-size: 30px;
          font-weight: bold;
          margin-top: 25px;
        }
        .sub_text{
          font-size: 8px;
          font-weight: normal;
          line-height: 25px;
        }
      }
    }
  }

}
.eduplace{
  .eduplace_list{
    .eduplace_item{
      width: 100%;
      min-height: 10vh;
      background: rgb(41, 41, 41);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 50px 0;
      &.set2{
        background: rgba(41, 41, 41, 0.558);
        .left{order:2}
        .right{order:1}
      }
      .left{
        width: 40%;
        .edu_slider {
          img{width: 100%;}
        }

      }
      .right{
        width: 35%;
        .title{
          font-size: 35px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 30px;
        }
        .desc{
          text-align: center;
          font-size: 16px;
          line-height: 150%;
          color: rgb(255,255,255,0.7);
        }
      }
    }
  }
}
.edu_intro_bigbg{
  position: relative;
  padding-bottom: 100px;

  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
    z-index: -1;
  }
  .intro_content{
    padding-top: 100px;
    margin:  0 15%;

    .title{
      font-size: 1.8rem;
      margin-bottom: 30px;
      color: #fff;
      letter-spacing: 1px;
      text-align: left;
      text-transform: capitalize;
      font-weight: 800;
    }
    .desc{
      font-size: 16px;
      color: #cacaca;
      letter-spacing: 1px;
      line-height: 150%;
      text-align: left;
      word-wrap: break-word;
      font-weight: 300;
    }
  }
}
.edu_latestCourse{
  margin: 0 15%;
  margin-top: 100px;
  h1{
    font-size: 1.8rem;
    margin-bottom: 30px;
    color: #ededed;
    letter-spacing: 1px;
    text-align: left;
    text-transform: capitalize;
    font-weight: 800;
  }
  .latestCourse_list{
    
    ul{
      display: flex;
      flex-wrap: wrap;
      .latestCourse_item{
        flex: 0 0 23%;
        padding-top: 32%;
        margin-right: 2%;
        margin-bottom: 2%;
        border-radius: 6px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;
        filter: grayscale(50%);
        &:hover{
          filter: grayscale(0%);
          transform: translateY(-5px);
        }
        &::before{
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 6px;
          background: rgb(0,0,0);
          background: linear-gradient(180deg, rgba(0,0,0,0.1) 40%, rgba(15, 15, 15, 0.89) 90%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background-blend-mode: multiply;
        }
        &:nth-child(4n+0){
          margin-right: 0;
        }
        .latestCourse_info{
          position: absolute;
          width: 100%;
          bottom: 5%;
          left:0%;
          z-index: 1;
          padding:15px;
          box-sizing: border-box;
          .title{
            font-size: 20px;
            font-weight: 100;
            text-align: left;
            color: #f3f3f3;
          }
          .bar{
            width: 10px;
            height: 3px;
            background: #fff;
            margin: 25px auto;
        
          }
          .name_subtext{
            text-align: center;
            font-weight: 300;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.course_single_modal{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  .blackbg{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.2s;
    opacity: 0;
    &.active{
      opacity: 1;
    }
  }
  .closeBtn{
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 17px;
    z-index: 10;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 300;
    font-family: Arial, sans-serif;
    background-image: radial-gradient(ellipse at top left,#42454ccc,#25282ee8);
    box-shadow: 0 0 32px #000;
    backdrop-filter: blur(5px);
    border: 1px solid transparent;
    cursor: pointer;
    &:hover{
      background: rgb(26, 26, 26);
    }
  }
  .btn_group{
    position: sticky;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 50%;
    transition: 0.3s;
    cursor: pointer;
    .icon{
      padding:15px;
      &:hover{
      background: rgb(74, 78, 88);
      border-radius: 3px;
      
      }
    }
  }
  .itemContent{
    position: fixed;
    top: 5%;
    left: 50%;
    z-index: 2;
    // min-width: 700px;
    width: 80%;
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    color:white;
    transition: 0.5s ease;
    transform: translate(-50%,0%);
    opacity: 0;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid transparent;
    border-radius: 4px;
    background-image: radial-gradient(ellipse at top left, #42454c61, #25282e78);
    box-shadow: 0 0 32px #000;
    backdrop-filter: blur(5px);
    &.header{
      border:0;
      background: none;
    }
    &.active{
      opacity: 1;
      transform: translate(-50%,1%);
    }
    .thumb{
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .img_thumb{
      min-height: 320px;
      max-height: 500px;
    }
    article{
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      margin: 9px;
      .header-title{
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: bold;
        text-align: center;
      }
      .article-content{
        padding: 20px 50px;
        font-size: 15px;
        line-height: 20px;
        font-weight: normal;
        font-family: arial;
        color:rgb(194, 194, 194);
        .one-text{

        }
        .one-image{
          .title{
            color:rgb(255, 255, 255);
            font-size: 18px;
            font-weight: normal;
            height: 30px;
          }
          img{
            width: 100%;
          }
        }
        .title-text{
          .title{
            color:rgb(255, 255, 255);
            font-size: 18px;
            font-weight: normal;
            height: 30px;
          }
        }
        .two-image{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          img{
            max-width:100%;
            display: block;
          }
        }
        .four-image{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          flex-wrap: wrap;
          div{
            flex : 1 0 50%;
            img{
              width: 100%;
            }
          }
        }
        .one-videos,.two-videos , .four-videos{
          .title{
            color:rgb(255, 255, 255);
            font-size: 18px;
            font-weight: normal;
            height: 30px;
          }
          .video-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .video-wrap{
              width: 100%;
              position: relative;
              padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
            }
          }
        }
      }

      .description{
        font-size: 12px;
        line-height: 21px;
        color:#ddd;
        max-height: 121px;
        overflow-y: auto;
        white-space: pre-line;
        margin-top: 10px;

        ul , li{
          list-style-type: square;
          margin-left: 10px;
        }
       
      }
      .space_image{
        img{
          width: 100%;
        }
      }
      .author{
        margin-top: 20px;
        font-size: 14px;
        color:#ddd;
      }
      .info{
        margin-top: 20px;
        font-size: 14px;
        color:#ddd;
      }
      .social{
        margin-top: 10px;
        width: 50%;
        display: flex;
        font-size: 12px;
        div{ margin-right: 12px;}
        a{
          text-decoration: none;
          color:#999;
          &:hover{
            color:white;
            border-bottom: 2px solid #fff;;
          }
        }
      }
    }
  }
}
.edu_Portfolio{
  margin-top: 150px;
  h1{
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 50px;
  }
  .edu_Portfolio_list{
    margin: 0 15%;
    ul{
      display: flex;
      flex-wrap: wrap;
      .edu_Portfolio_item{
        flex: 0 0 24%;
        height: 169px;
        margin: 3px;
        transition: 0.3s;
        opacity: 0.7;
        cursor: pointer;
        position: relative;
        &:hover{
            opacity: 1;
            z-index: 5;
          .item_bg{
            transform: scale(1.1);
            
          }
          
        }
        .item_bg{
          width: 100%;;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;   
          transition: 0.3s;
          z-index: 1;
        }
        .title{
          position: absolute;
          z-index: 2;
          font-size: 14pt;
          bottom: 5px;
          left: 5px;
        }
      }
    }
  }

}