.mobile-menu{display: none;}
@media screen and (max-width:1024px) {

}
@media screen and (max-width:830px) {
  .container{
    width: 100%;
    margin: 0 auto;
  }
  .pageHeader{
    .caption{
      width: 100%;
      .desc{
        letter-spacing: 1px;
        font-size: 15px;
        line-height: 150%;
      }
    }
  }
  #header{
    .caption{
      width: 80%;
      .title{
        font-size: 2rem;
      }
      p{
        font-size: 1rem;
      }
    }
  }
  #navbar{
    .navlist {
      ul {
        li{
          a{
            padding: 1rem 6px;
            color: #a5a5a5;
          }
        }
      }
    }
  }
  #works {
    .works_list{
      
      .item {
        flex: 1 0 25%;
        .item_bg{
          height: 169px;
        }
      }
    } 
  }
  .workitem {
    .itemContent{
      width: 80%;
    }
  }
  #intro {
    .about_intro {
      .content {
        .left_container{
          overflow: initial;
          .left_word{
            .title{
              font-size: 1rem;
              margin-bottom: 10px;
            }
          }
       }
      }  
    }
  }
  #intro_bigbg{
    .intro_content{
      padding:5rem 2rem;
      .title{
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 10px;
      }
      .desc{
        font-size: 14px;
      }
    }
  }
  #about{
    .about_item{
      flex-direction: column;
      &:nth-child(2) , &:nth-child(4){
        .about_item_text{
          order: 1;
        }
        .about_item_img{
          order: 2;
        }
      }
      .about_item_text {
        width: 100%;
        order:1;
        padding: 10px;
        .title{
          font-size: 1rem;
          margin-bottom: 10px;
        }
        .desc{
          font-size: 12px;
        }
      }
      .about_item_img {
        width: 100%;
        order:2;
      }
    }
    .about_detail{
      .about_detail_list{
        .about_slider{
          .item{
            padding-top: 180%;
          }
        }
      }  
    } 
  } 
  #About_staff{
    margin-top: -450px;
    .about_department_list{
      ul{
        flex-wrap: wrap;
        justify-content: flex-start;
        li{
          padding: 5px 9px;
          &:first-child{
          }
        }
      }
    }
    .aboutstaff_list{
      .aboutstaff_item{
        .person_info{
          .name{
            font-size: 1.2rem;
          }
          .bar{
            margin: 10px auto;
          }
        }
      }
    }
  }
  #Pages_services{
    #servives_about{
      margin-top: -450px;
    }
  }
  .edu_latestCourse{
    .latestCourse_list{
      ul{
        .latestCourse_item {
          .latestCourse_info{
            .title{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .edu_Portfolio{
    .edu_Portfolio_list{
      ul{
        .edu_Portfolio_item{
          flex: 0 0 23.3%;
          height: 139px;
        }
      } 
    } 
  } 

  
}
@media screen and (max-width: 420px){
  #navbar{
    .navlist{
      display: none;
    }
    .mobileNavBtn{
      display: flex;
      div{
        margin-left: 26px;
      }
    }

  }
  .mobile_navlist{
    display: block;
    position: fixed;
    top: -1000px;
    left: 0;
    padding: 50px 0 20px 0;
    width: 100%;
    list-style: none;
    z-index: 20;
    transition: 0.5s;
    overflow: hidden;
    color: #fff;
    background-image: radial-gradient(ellipse at top left, rgba(66, 69, 76, 0.3803921569), rgba(37, 40, 46, 0.4705882353));
    box-shadow: 0 0 32px #000;
    backdrop-filter: blur(5px);

    &.active{
      top:0;
    }
    ul{
      li{
        a{
          display: block;
          padding: 15px 35px;
          display: block;
          color:#acacac;
          transition: 0.3s;
          text-decoration: none;
          &.active{
            color: white;
            font-weight: bold;
          }
        }
      }
    }
  }
  section{
    margin: 0 0%;
    padding: 6px 8px;
  }
  #works {
    .works_categories_list{
      ul{
        flex-wrap: wrap;
        li{
          font-size: 11pt;
        }
      }
    }
    .works_list{
      .item{
        flex: 1 0 45%;
        .item_bg{
          height: 190px;
        }
      }
    } 
  }
  .workitem{
    .itemContent{
      width: 90%;
 
    }
    .img_itemContent{
      width: 90%;
      .img_thumb{
        min-height: 200px;
        max-height: 300px;
      }
    }
  
  }
  #intro{
    .about_intro{
      .content{
        flex-direction: column;
        .left_container{
          width: 100%;
          padding-top: 59%;
          .left_word{
            width: 100%;
            padding:15px;
            top: 0;
            left: 0;

          }
        }

        .right_img{
          width: 100%;
          position: relative;
          top: 0px;
          right: 0px;
        }
      }
    }
  }
  #intro_bigbg{
    .intro_content{
      padding:1rem 0rem;
      margin:  0 5%;
      .title{
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 10px;
      }
      .desc{
        font-size: 14px;
      }
    }
  }
  #about{
    .about_item{
      flex-direction: column;
      &:nth-child(2) , &:nth-child(4){
        .about_item_text{
          order: 1;
        }
        .about_item_img{
          order: 2;
        }
      }
      .about_item_text {
        width: 100%;
        order:1;
        padding: 10px;
        .title{
          font-size: 1rem;
          margin-bottom: 10px;
        }
        .desc{
          font-size: 12px;
        }
      }
      .about_item_img {
        width: 100%;
        order:2;
      }
    }
    .about_detail{
      flex-direction: column;
      .about_detail_textcontent{
        width: 100%;
        padding: 1rem 1rem;
        box-sizing: border-box;
      }
      .about_detail_list{
        width: 100%;
      }
    }
  }  
  #space{
    .space_list{
      flex-direction: column;
      .space_item{
        width: 100%;
      }
    }
  }
  #technology{
    h1{ text-align: center;}
    .technology_content{
      flex-direction: column;
      .technology_list{
        width: 100%;
        margin-right: 0px;
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }
  #partner{
    .partner_list{
      ul{
        li{
          flex: 1 0 45%;

        }
      }
    }
  }
  #About_staff{
    margin-top: -250px;
    .aboutstaff_list{
      .aboutstaff_item{
        flex: 0 0 48%;
        padding-top: 55%;
        margin-right: 2.5%;
        margin-bottom: 2.5%;
        &:nth-child(2n+0){
          margin-right: 0;
        }
      }
    } 
  }
  #Pages_services{
    #servives_about{
      margin-top: -250px;
      .about_item{
        flex-direction: column;
        &:nth-child(2), &:nth-child(4){
          .about_item_text{order:1}
          .about_item_img{order:2}
        }
        .about_item_text{
          width: 100%;
        }
        .about_item_img{
          width: 100%;
        }
      }
    }
  }
  .pageHeader{
    .caption{
      width: 90%;
    }
  } 
  .chartlist{
    ul{
      box-sizing: border-box;
      padding:15\6px;
      align-items: flex-start;
      li{
        .chartlist_item{
          img{
            box-sizing: border-box;
            height: 40px;

          }
          .title{
            font-size: 0.8rem;
          }
          .number{
            margin-top: 20px;
          }
          .sub_text{
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .eduplace {
    .eduplace_list {
      .eduplace_item{
        flex-direction: column;
        box-sizing: border-box;
        padding:30px;
        &:first-child{
          .left{order:2}
          .right{order:1}
        }
        .left{
          width: 100%;
        }
        .right{
          width: 100%;
          margin-bottom: 30px;
          .title{
              font-size: 2rem;
          }
        }
      }
    }
  }
  .edu_intro_bigbg {
    .intro_content{
      margin: 0 5%;
    }
  }
  .edu_latestCourse {
    margin: 0 5%;
    .latestCourse_list {
      ul {
        .latestCourse_item{
          flex: 0 0 48%;
          padding-top: 70%;
        }
      }
    }
  }
  .edu_Portfolio{
    .edu_Portfolio_list{
      margin: 0 5%;
      ul{
       .edu_Portfolio_item{
          flex: 0 0 47%;
          height: 129px;
        } 
      }
    }
  }
  .course_single_modal{
    .itemContent{
      width: 90%;
      article{
        .article-content{
          padding: 9px 9px;
        }
      }
    } 
  } 

  

}
